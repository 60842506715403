<template>
  <div class="users-index width-100">
    <Filters
      :filters="usersFilters"
      filter-key="users"
      :initial-data="filtersStatus.users"
    />

    <FloatingActionButton
      color="green"
      icon="fas fa-plus"
      :click-function="() => openCreateUserModal = true"
      :label="$t('users.create.createUser')"
    />

    <CreateUser
      v-if="openCreateUserModal"
      :visible="true"
      :is-modal-edit="isModalEdit"
      :initial-data="initialUserData"
      @dialogClose="handleDialogClose"
    />

    <TableWrapper
      :table-columns="usersColumns"
      table-key="users"
      :enable-selection="true"
      :bulk-disable="true"
      @bulkDisable="bulkDisableSelectedUsers"
      @enableUser="enableUser"
      @disableUser="disableUser"
      @editUser="goToEdit"
      @userOrders="goToUserOrders"
      @sendVerificationEmail="sendVerificationEmail"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TableWrapper from '@/components/TableWrapper.vue';
import Filters from '@/components/Filters.vue';
import CreateUser from '@/views/users/CreateUser.vue';
import FloatingActionButton from '@/components/FloatingActionButton.vue';
import { usersColumns, usersFilters } from '../../config';
import { usersApi } from '../../api';

export default {
  name: 'UsersIndex',
  components: {
    TableWrapper,
    Filters,
    CreateUser,
    FloatingActionButton,
  },
  data() {
    return {
      usersColumns,
      usersFilters,
      openCreateUserModal: false,
      isModalEdit: false,
      initialUserData: {},
      selectedUsers: [],
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.userId) {
        vm.getUser();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
      'setLoggedUser',
    ]),
    handleDialogClose(status) {
      this.openCreateUserModal = false;
      if (status.success) {
        this.tableRows();
      }
      if (this.isModalEdit) {
        this.isModalEdit = false;
        this.$router.push({ name: 'users' });
      }
    },
    async tableRows() {
      try {
        const usersIndexResponse = await usersApi.index(this.tableFilters('users'), this.filtersStatus.users);
        const usersCountResponse = await usersApi.count(this.filtersStatus.users);
        this.updateTableRows({ tableKey: 'users', rows: usersIndexResponse.data });
        this.updateTableCount({ tableKey: 'users', count: usersCountResponse.data.count });
      } catch (error) {
        throw error;
      }
    },
    async getUser() {
      try {
        const user = await usersApi.show(this.$route.params.userId);
        this.initialUserData = {
          givenName: user.data.givenName,
          familyName: user.data.familyName,
          email: user.data.email,
          function: user.data.function,
          customerId: user.data.customerId,
          customerName: user.data.customer ? user.data.customer.name : '',
          permissions: user.data.permissions,
          isEnabled: user.data.isEnabled,
          isVerified: user.data.isVerified,
        };
        this.isModalEdit = true;
        this.openCreateUserModal = true;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        this.$router.push({
          name: 'users',
        });
      }
    },
    async enableUser(row) {
      try {
        await usersApi.patch(row.id, { isEnabled: true });
        this.tableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('users.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async disableUser(row) {
      try {
        await usersApi.patch(row.id, { isEnabled: false });
        this.tableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('users.update.success') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async bulkDisableSelectedUsers(selectedUsers) {
      try {
        if (selectedUsers.length) {
          const buildWhereCondition = users => ({
            id: {
              inq: users.map(user => user.id),
            },
          });
          await usersApi
            .bulkUpdate({ isEnabled: false }, buildWhereCondition(selectedUsers));
          this.tableRows();
        }
        this.$message({ showClose: true, type: 'success', message: this.$t('users.update.success') });
      } catch (error) {
        throw error;
      }
    },
    goToEdit(row) {
      this.$router.push({
        name: 'usersEdit',
        params: { userId: row.id },
      });
    },
    goToUserOrders(row) {
      this.$router.push({
        name: 'userOrdersIndex',
        params: { userId: row.id },
      });
    },
    async sendVerificationEmail(row) {
      try {
        await usersApi.sendInvite(row.id);
        this.tableRows();
        this.$notify({ showClose: true, type: 'success', message: this.$t('users.update.invitationSent') });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
  },
};
</script>
