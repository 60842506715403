<template>
  <el-row
    type="flex"
    justify="end"
    class="form__buttons-row"
  >
    <el-col :span="24">
      <FormItem margin-bottom-size="none">
        <slot />
      </FormItem>
    </el-col>
  </el-row>
</template>

<script>
import FormItem from '@/components/FormItem.vue';

export default {
  name: 'FormButtons',
  components: {
    FormItem,
  },
};
</script>

<style lang="scss">
.form {
  &__buttons-row {
    margin-top: 30px;
    text-align: right;
  }
}
</style>
