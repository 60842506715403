<template>
  <ValidationProvider
    v-slot="{ errors, invalid, dirty, validated }"
    :vid="vid"
    :rules="rules"
    :name="label"
  >
    <el-form-item
      :class="[
        'form-item',
        formItemClass,
        {'is-error': (invalid && (dirty || validated))},
        `margin-bottom-size--${marginBottomSize}`,
      ]"
      :size="size"
    >
      <span slot="label">
        {{ label }}
        <InfoTooltip
          v-if="help"
          :tooltip-content="help"
        />
      </span>
      <slot />
      <small
        v-if="(invalid && (dirty || validated))"
        class="el-form-item__error"
      >{{ errors[0] }}</small>
    </el-form-item>
  </ValidationProvider>
</template>

<script>
import InfoTooltip from '@/components/InfoTooltip.vue';

export default {
  name: 'FormItem',
  components: {
    InfoTooltip,
  },
  props: {
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    help: { type: String, default: '' },
    size: { type: String, default: '' },
    rules: { type: [String, Object], default: '' },
    vid: { type: String, default: '' },
    marginBottomSize: {
      type: String,
      default: 'auto',
      validator: value => ['auto', 'none'].includes(value),
    },
  },
  data() {
    return {
      formItemClass: '',
    };
  },
  created() {
    if (this.rules !== '' && typeof this.rules === 'object') {
      this.formItemClass = this.rules.required ? 'is-required' : '';
    } else {
      this.formItemClass = this.rules.split('|').includes('required') ? 'is-required' : '';
    }
  },
};
</script>

<style lang="scss">
.form-item.el-form-item {
  &.margin-bottom-size {
    &--none {
      margin-bottom: 0;
    }

    &--auto {
      margin-bottom: 2.2rem;
    }
  }

  .el-form-item__label {
    min-height: 38px;
  }
}
</style>
