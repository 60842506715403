<template>
  <el-button
    :class="`floating-action-button floating-action-button--${color}`"
    :icon="`floating-action-button__icon ${icon}`"
    @click="clickFunction"
  >
    {{ label }}
  </el-button>
</template>

<script>
export default {
  name: 'FloatingActionButton',
  props: {
    color: {
      type: String,
      default: 'green',
      validator: value => ['green'].includes(value),
    },
    icon: { type: String, default: '' },
    clickFunction: { type: Function, default: () => {} },
    label: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.el-button.floating-action-button {
  position: fixed;
  bottom: $--main-padding;
  right: $--main-padding;
  z-index: 10;
  display: flex;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #949494;
  text-transform: uppercase;
  line-height: 26px;

  @each $color, $value in $table-action-colors {
    &--#{$color} {
      background-color: $value;
      border-color: $value;
      color: $--color-white;

      &:hover {
        filter: brightness(0.9);
        background-color: $value;
        border-color: $value;
        color: $--color-white;
      }

      &:focus {
        background-color: $value;
        border-color: $value;
        color: $--color-white;
      }
    }
  }

  .floating-action-button {
    &__icon {
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
</style>
