<template>
  <el-dialog
    class="dialog-wrapper"
    :visible="visible"
    @close="abortCreate"
  >
    <ContentHeader
      slot="title"
      :title="isModalEdit ? $t('users.update.updateUser') : $t('users.create.createUser')"
    />
    <ValidationObserver
      ref="userForm"
      v-slot="{ handleSubmit, invalid }"
    >
      <el-form
        ref="form"
        label-position="top"
      >
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="8">
            <FormItem
              rules="required"
              :label="$t('users.create.givenName')"
            >
              <el-input
                v-model="formData.givenName"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              rules="required"
              :label="$t('users.create.familyName')"
            >
              <el-input
                v-model="formData.familyName"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              rules="required|email"
              vid="email"
              :label="$t('users.create.email')"
            >
              <el-input
                v-model="formData.email"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="8">
            <FormItem
              v-if="!isModalEdit"
              rules="required|email|confirmed:email"
              :label="$t('users.create.emailConfirmation')"
            >
              <el-input
                v-model="formData.emailConfirmation"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              v-if="!isModalEdit"
              rules="required|min:12"
              vid="password"
              :label="$t('users.create.password')"
            >
              <el-input
                v-model="formData.password"
                show-password
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              v-if="!isModalEdit"
              rules="required|confirmed:password"
              :label="$t('users.create.passwordConfirmation')"
            >
              <el-input
                v-model="formData.passwordConfirmation"
                show-password
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="8">
            <FormItem
              :label="$t('users.create.customer')"
              rules="required"
            >
              <el-autocomplete
                v-if="userPermissions && userPermissions.includes('admin')"
                v-model="formData.customerName"
                style="width: 100%"
                :fetch-suggestions="customersSearch"
                :trigger-on-focus="false"
                value-key="label"
                placeholder=""
                @select="handleSelectAutocomplete"
                @input="formData.customerId = ''"
              />
              <el-input
                v-else
                v-model="formData.customerName"
                disabled
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('users.create.function')"
            >
              <el-select
                v-model="formData.function"
                placeholder=""
              >
                <el-option
                  :label="$t('users.create.functions.qc')"
                  :value="0"
                />
                <el-option
                  :label="$t('users.create.functions.qa')"
                  :value="1"
                />
                <el-option
                  :label="$t('users.create.functions.rd')"
                  :value="2"
                />
                <el-option
                  :label="$t('users.create.functions.production')"
                  :value="3"
                />
                <el-option
                  :label="$t('users.create.functions.administration')"
                  :value="4"
                />
                <el-option
                  :label="$t('users.create.functions.logistics')"
                  :value="5"
                />
                <el-option
                  :label="$t('users.create.functions.purOff')"
                  :value="6"
                />
              </el-select>
            </FormItem>
          </el-col>
        </el-row>

        <!-- Permissions -->
        <label class="user-modal__permissions-text el-form-item__label">
          {{ $t('permissions.title') }}
        </label>

        <el-row
          :gutter="20"
          type="flex"
          class="user-modal__permissions-row"
        >
          <el-col
            v-for="(permission, index) in permittedPermissions"
            :key="index"
            :span="8"
            class="user-modal__permissions-col"
          >
            <el-checkbox
              v-if="permission.children"
              class="user-modal__permission-category"
              :value="groupIsChecked(index)"
              @change="checkAllGroup(index)"
            >
              {{ $t(`permissions.${permission.label}`) }}
            </el-checkbox>

            <el-checkbox-group
              v-if="permission.children"
              v-model="formData.permissions"
            >
              <el-checkbox
                v-for="child in permission.children"
                :key="child.value"
                :label="child.value"
                class="user-modal__permission-item"
              >
                {{ $t(`permissions.${child.label}`) }}
              </el-checkbox>
            </el-checkbox-group>

            <el-checkbox-group
              v-if="!permission.children"
              v-model="formData.permissions"
            >
              <el-checkbox
                :label="permission.value"
                class="user-modal__permission-category"
              >
                {{ $t(`permissions.${permission.label}`) }}
              </el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
        <!-- End Permissions -->

        <FormButtons>
          <el-button
            type="secondary"
            @click.prevent="abortCreate"
          >
            {{ $t('users.create.abortCreate') }}
          </el-button>
          <el-button
            v-if="isModalEdit"
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(updateUser)"
          >
            {{ $t('users.update.updateUser') }}
          </el-button>
          <el-button
            v-else
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(createUser)"
          >
            {{ $t('users.create.createUser') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { usersApi, customersApi } from '@/api';
import { permissionsList } from '@/config/constants';
import acl from '@/services/acl';
import FormItem from '@/components/FormItem.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import FormButtons from '@/components/FormButtons.vue';

const defaultData = {
  givenName: '',
  familyName: '',
  email: '',
  emailConfirmation: '',
  password: '',
  passwordConfirmation: '',
  function: 0,
  permissions: [],
  customerId: '',
  customerName: '',
  isEnabled: true,
  isVerified: false,
};

export default {
  name: 'CreateUser',
  components: {
    FormItem,
    ContentHeader,
    FormButtons,
  },
  props: {
    visible: { type: Boolean, default: false },
    isModalEdit: { type: Boolean, default: false },
    initialData: { type: Object, default: () => defaultData },
  },
  data() {
    return {
      formData: { ...defaultData },
      permissionsList,
    };
  },
  computed: {
    ...mapGetters([
      'loggedUser',
      'userPermissions',
    ]),
    permittedPermissions() {
      if (acl.userCanUse(['admin'])) {
        return permissionsList;
      }
      return permissionsList.reduce((permissions, currentPermission) => {
        if (currentPermission.value !== 'admin' && currentPermission.label !== 'customers') {
          const currentChildren = currentPermission.children.filter(p => p.value !== 'newsManage' && p.value !== 'offersManage' && p.value !== 'samplesStatusManage' && p.value !== 'billingManage') || [];
          const filteredPermission = {
            label: currentPermission.label,
            children: currentChildren.length ? currentChildren : [currentChildren],
          };
          return [...permissions, filteredPermission];
        }
        return permissions;
      }, []);
    },
  },
  // watch: {
  //   initialData(data) {
  //     if (Object.keys(data).length > 0) {
  //       this.formData = { ...data };
  //     }
  //   },
  // },
  created() {
    if (this.loggedUser && this.loggedUser.customer) {
      this.formData.customerName = this.loggedUser.customer.name;
      this.formData.customerId = this.loggedUser.customer.id;
    }
    if (this.isModalEdit) {
      this.formData = { ...this.initialData };
    }
  },
  methods: {
    async createUser() {
      try {
        await usersApi.create({
          givenName: this.formData.givenName,
          familyName: this.formData.familyName,
          email: this.formData.email,
          password: this.formData.password,
          permissions: this.formData.permissions,
          function: this.formData.function,
          // phone: this.formData.phone,
          customerId: this.formData.customerId,
          isEnabled: this.formData.isEnabled,
          isVerified: this.formData.isVerified,
        });
        this.$notify({ showClose: true, type: 'success', message: this.$t('users.create.success') });
        this.formData = { ...defaultData };
        this.$refs.userForm.reset();
        this.$emit('dialogClose', { success: true });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async updateUser() {
      try {
        await usersApi.patch(this.$route.params.userId, {
          givenName: this.formData.givenName,
          familyName: this.formData.familyName,
          email: this.formData.email,
          permissions: this.formData.permissions,
          function: this.formData.function,
          // phone: this.formData.phone,
          customerId: this.formData.customerId,
        });
        this.$notify({ showClose: true, type: 'success', message: this.$t('users.update.success') });
        this.formData = { ...defaultData };
        this.$refs.userForm.reset();
        this.$emit('dialogClose', { success: true });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async customersSearch(value, cb) {
      if (value.length >= 3) {
        const results = await customersApi.autocompleteFetch(value);
        const filteredResults = results.data.map(item => (
          {
            label: item.name,
            value: item.id,
          }
        ));
        cb(filteredResults);
      } else {
        cb([]);
      }
    },
    handleSelectAutocomplete(item) {
      this.formData.customerId = item.value;
    },
    abortCreate() {
      this.formData = { ...defaultData };
      this.$refs.userForm.reset();
      this.$emit('dialogClose', { success: false });
    },
    groupIsChecked(index) {
      const groupPermissions = this.permittedPermissions[index].children.map(c => c.value);
      return groupPermissions.every(p => this.formData.permissions.includes(p));
    },
    checkAllGroup(index) {
      const groupPermissions = this.permittedPermissions[index].children.map(c => c.value);
      const groupChecked = this.groupIsChecked(index);
      this.formData.permissions = this.formData.permissions
        .filter(p => !groupPermissions.includes(p));
      if (!groupChecked) {
        this.formData.permissions = [
          ...this.formData.permissions,
          ...groupPermissions,
        ];
      }
    },
  },
};
</script>

<style lang="scss">
  .user-modal {
    &__permissions-text {
      color: $--color-primary;
    }

    &__permissions-row {
      flex-wrap: wrap;
    }

    &__permissions-col {
      margin-bottom: 1.25rem;
    }

    &__permission-category {
      margin-bottom: 0.5rem;
    }

    &__permission-item {
      padding-left: 0.5rem;
      display: block;
    }
  }
</style>
